// Bootstrap functions
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";
@import "~bootstrap/scss/utilities";

// LazyLoad

img:not([src]):not([srcset]) {
	visibility: hidden;
}

.card-swiper {
	padding-left: 40px;
	padding-right: 40px;
	position: relative;

	@media (min-width: map-get($grid-breakpoints, md)) {
		padding-left: 60px;
		padding-right: 60px;
	}
}

.swiper-button-next,
.swiper-button-prev {
	background-color: transparent;
	background-size: 100%;
	background-position: center;
	background-repeat: no-repeat;
	width: 40px;
	height: 40px;
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
	z-index: 10;
	cursor: pointer;
	transition: background-color .3s;
}

.swiper-button-prev {
	background: url("../images/swiper-left.svg") center no-repeat;
	left: 0;

	&:after {
		content: none;
	}
}

.swiper-button-next {
	background: url("../images/swiper-right.svg") center no-repeat;
	right: 0;

	&:after {
		content: none;
	}
}
